<template>
	<div class="datasoures">
		<div style="display: flex; width: 100%">
			<Headbuttom></Headbuttom>
			<Tabheader @handRegister="handRegister" @handRegisterall="handRegisterall"
				@handRegistertbu="handRegistertbu" @handFromshow="handFromshow" :titlename="titlename">
			</Tabheader>
		</div>
		<div class="fromshea" v-show="iShow">
			<el-form :inline="true" size="small" :model="formInline" class="demo-form-inline">
				<el-form-item :label="$t('devtable.bm')">
					<Select @handleCheckChange="handleCheckChange" :placeholder="$t('devtable.qxz')"></Select>
				</el-form-item>
				<el-form-item :label="$t('devtable.xm')">
					<el-input v-model="formInline.name" :placeholder="$t('devtable.xm')"></el-input>
				</el-form-item>

				<el-form-item :label="$t('devtable.gh')">
					<el-input v-model="formInline.number" :placeholder="$t('devtable.gh')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('devtable.sjhm')">
					<el-input v-model="formInline.mobile" :placeholder="$t('devtable.sjhm')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('devtable.kh')">
					<el-input v-model="formInline.card_no" :placeholder="$t('devtable.kh')"></el-input>
				</el-form-item>
				<!-- 审核状态 -->
				<el-form-item :label="$t('devtable.shzt')">
					<el-select v-model="value" @change='handleExamineChange'>
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- 注册状态 -->
				<el-form-item :label="$t('devtable.zczt')">
					<el-select v-model="registerValue" @change='handleRegisterChange'>
						<el-option v-for="item in registerOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="onSubmit">{{
            $t("devtable.cx")
          }}</el-button>
				</el-form-item>
				
			</el-form>
		</div>
		<Tables :tableLabel="tableLabel" @sortChange="sortChange" @handles="handles" @handlpages="handlpages"
			@handleSizeChange="handleSizeChange" @handleSelectionChange="handleSelectionChange" :tableData="tableData"
			:flags="flags" :configs="configs"></Tables>
		<Selfauditdiolg ref="selfauditdiolg"></Selfauditdiolg>
		<Diolg ref="diolg" @handleClose="handleClose" @dialogVisibletrue="dialogVisibletrue">
			<div style="display: flex; justify-content: center; max-height: 250px">
				<el-image :src="src"></el-image>
			</div>
		</Diolg>
	</div>
</template>
<script>
	import Tables from "@/components/table";
	import Tabheader from "./components/heaerbutom";
	import Selfauditdiolg from "./components/selfauditdiolg.vue";
	import {
		getLocalTime
	} from "@/utils";
	import Diolg from "@/components/dialog/img.vue";
	import Select from "@/components/selectree/sele";
	import {
		formatDate
	} from "../../utils/date";
	export default {
		components: {
			Tables,
			Tabheader,
			Selfauditdiolg,
			Diolg,
			Select,
		},
		data() {
			return {
				language:window.localStorage.getItem('language'),
				approve_order_by: 0,
				status_order_by: -1,
				src: "",
				id: "", // 审核是的id
				iShow: false,
				formInline: {
					name: "",
					mobile: "",
					card_no: "",
					agent_id: "",
					dept_id: "",
					number: "",
				},
				input: "",
				centerDialogVisible: false,

				tableData: [],
				flags: {
					show: true, // 多选是否显示
					ishow: false, // 序号是否显示
				},
				// 分页数据
				configs: {
					page_index: 1,
					pagesize: 10,
					total: 200,
					loading: false, // 加载状态
				},
				// dialog:{
				//     dialogVisible:false,
				//     title:'预览人像照片'
				// },
				UserInfo: "",

				showid: [],
				showid2: [],
				everypage: [],
				device_list_0: "",
				device_list_1: "",
				device_list_1: "",
				indexst: 0,
				jj: false,
				options: [ // 审核状态 下拉列表
				{
					value: '0',
					label: this.$t('devtable.qbmr')
				},{
					value: '2',
					label: this.$t('devtable.ysh')
				},{
					value: '1',
					label: this.$t('devtable.wsh')
				}],
				value:'',  // 审核状态 帮顶值
				registerOptions:[ // 注册状态 下拉列表值
					{
						value: '0',
						label: this.$t('devtable.qbmr')
					},{
						value: '2',
						label: this.$t('devtable.yzc')
					},{
						value: '1',
						label: this.$t('devtable.wzc')
					},{
						value: '3',
						label: this.$t('devtable.zcsb')
					}
				],
				registerValue:'',  // 审核状态 帮顶值
			};
		},
		mounted() {
			this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
			this.formInline.agent_id = this.UserInfo.agent_id;
			this.getableDate();
			this.getshowlist();
			this.showid = [];
		},
		computed: {
			titlename: function() {
				return {
					name: this.$t("devtable.sh"),
					titles: this.$t("devtable.plsh"),
					tlnames: this.$t("devtable.qbsh"),
				};
			},
			tableLabel: function() { // 表头 数据表
				return [{
						prop: "id",
						label: this.$t("devtable.zcid"),
						align: "center",
						width:this.language=='en'?'125px':''
					},
					{
						prop: "name",
						label: this.$t("devtable.xm"),
						align: "center",
					},
					{
						prop: "mobile",
						label: this.$t("devtable.sjhm"),
						align: "center",
					},
					{
						prop: "gender",
						label: this.$t("devtable.xb"),
						align: "center",
					},
					{
						prop: "dept_name",
						label: this.$t("devtable.bm"),
						align: "center",
						width:this.language=='en'?'100px':''
					},
					{ // 审核状态
						prop: "approve_state",
						label: this.$t("devtable.shzt"),
						align: "center",
						width: this.language=='en'?'130px':'120px',
						sortable: true,
					},
					{ // 注册状态
						prop: "reg_face_status",
						label: this.$t("devtable.zczt"),
						align: "center",
						width: this.language=='en'?'175px':'120px',
						sortable: true,
					},
					{ // 认证类型
						prop: "rlnumber",
						label: this.$t("devtable.rzlx"),
						align: "center",
						width: this.language=='en'?'120px':'',
					},
					{
						prop: "face_url",
						label: this.$t("devtable.rxzp"),
						align: "center",
						width: this.language=='en'?'110px':'120px',
					},
					// {
					// 	prop: "approve_name",
					// 	label: this.$t("devtable.shr"),
					// 	align: "center",
					// 	width: this.language=='en'?'90px':'',
					// },
					{
						prop: "approve_time",
						label: this.$t("devtable.shsj"),
						align: "center",
						width: "180",
						sortable: true,
					},
				];
			},

			most: function() {
				return {
					ysp: this.$t("devtable.ysp"),
					wsp: this.$t("devtable.wsp"),
					male: this.$t("devtable.male"),
					female: this.$t("devtable.female"),
					yzc: this.$t("devtable.yzc"),
					wzc: this.$t("devtable.wzc"),
					bfzc: this.$t("devtable.bfzc"),
					zcsb: this.$t("devtable.zcsb"),
				};
			},
		},
		methods: {
			// 获取企业部门id
			handleCheckChange(val) {
				this.formInline.dept_id = val;
				console.log(`677`, this.formInline.dept_id);
			},
			// 审核状态
			handleExamineChange() {
				console.log(this.value)
			},
			// 注册状态 搜索选择
			handleRegisterChange(){
				console.log(this.registerValue)
			},
			//分页
			handlpages(v) {
				this.configs.page_index = v
				this.getableDate();
			},
			handleSizeChange(val) {
				this.configs.page_index = 1
				this.configs.pagesize = val
				this.getableDate();
			},
			// 获取所有数据

			getshowlist() {
				let data = {
					method: "co.approve.list",
					page_index: 1,
					page_size: 10,
					name: "",
					mobile: "",
					manager_id: this.UserInfo.manager_id,
					card_no: "",
					agent_id: this.UserInfo.agent_id,
					reg_face_status: "",
					dept_id: ""
				};
				this.$serve(data).then((res) => {
					let data = res.data.data;
					if (res.data.code == 0) {
						data.forEach((el) => {
							this.showid2.push(el.id);
							// if((new Date(el.approve_time*1000).getFullYear())<2000){
							// 	el.approve_time = ''
								
							// 	console.log(el.approve_time)
							// }
							;
						});
					}
				});
			},
			// 排序
			sortChange(v) {
				console.log(v);
				if (
					(v.column.label == "审核时间" || v.column.label == "审核时间") && // 审核时间 descending 降序
					v.order == "descending"
				) {
					this.status_order_by = -1;
					this.approve_order_by = 1;
					this.getableDate();
				}
				if (v.column.label == "审核时间" && v.order == "ascending") { // 审核时间 ascending 升序
					this.status_order_by = -1;
					this.approve_order_by = 0;
					this.getableDate();
				}

				if (v.column.label == "注册状态" && v.order == "descending") { // 注册状态 descending 降序
					this.approve_order_by = -1;
					this.status_order_by = 1;
					this.getableDate();
				}
				if (v.column.label == "注册状态" && v.order == "ascending") { // 注册状态 ascending 升序
					this.approve_order_by = -1;
					this.status_order_by = 0;
					this.getableDate();
				}
				if (v.column.label == "审核状态" && v.order == "ascending") { // 审核状态 ascending 升序
					this.status_order_by = -1;
					this.approve_order_by = 0;
					this.getableDate();
				}
				if (v.column.label == "审核状态" && v.order == "descending") { // 审核状态 descending 降序
					this.status_order_by = -1;
					this.approve_order_by = 1;
					this.getableDate();
				}
				if (v.order == null) {
					this.status_order_by = -1;
					this.approve_order_by = -1;
					this.getableDate();
				}
			},
			//获取列表数据
			getableDate(v, val) {
				let data = {
					method: "co.approve.list",
					page_index: this.configs.page_index,
					page_size: this.configs.pagesize,
					name: this.formInline.name ? this.formInline.name : "",
					mobile: this.formInline.mobile ? this.formInline.mobile : "",
					manager_id: this.UserInfo.manager_id,
					card_no: this.formInline.card_no ? this.formInline.card_no : "",
					agent_id: this.UserInfo.agent_id,
					reg_face_status: this.registerValue,
					dept_id: this.formInline.dept_id ? this.formInline.dept_id : "",
					approve_order_by: this.approve_order_by,
					status_order_by: this.status_order_by,
					approve_status:this.value,
					
				};
				this.$serve(data).then((res) => {
					console.log(`32`, res);
					let data = res.data.data;
					data.forEach((element) => {
						if (element.gender == 0) {
							element.gender = {
								name: this.$t("devtable.male"),
								state: true,
								type: "primary",
							};
						} else {
							element.gender = {
								name: this.$t("devtable.female"),
								state: true,
								type: "success",
							};
						}
						if (element.approve_state == 0) {
							element.approve_state = {
								name: this.most.wsp,
								state: true,
								type: "info",
							};
						} else {
							element.approve_state = {
								name: this.most.ysp,
								state: true,
								type: "success",
							};
						}

						if (element.reg_face_status == 0) {
							element.reg_face_status = {
								name: this.most.wzc,
								state: true,
								type: "warning",
							};
						} else if (element.reg_face_status == 1) {
							element.reg_face_status = {
								name: this.most.bfzc,
								state: true,
								type: "primary",
							};
						} else if (element.reg_face_status == 2) {
							element.reg_face_status = {
								name: this.most.yzc,
								state: true,
								type: "primary",
							};
						} else {
							element.reg_face_status = {
								name: this.$t("devtable.zcsb"),
								state: true,
								type: "warning",
							};
						}
						element.rlnumber = {
							name: this.$t("devtable.yg"),
							state: true,
							type: "primary",
						};
						if((new Date(element.approve_time*1000).getFullYear())>2000){
							element.approve_time = formatDate(
								
								new Date(element.approve_time * 1000),
								"yyyy-MM-dd hh:mm"
							);
						}
						
						let src = element.face_url;
						element.face_url = {
							name: this.$t("devtable.yl"),
							but: true,
							type: "info",
							src: src,
						};
					});
					this.tableData = res.data.data;
					this.configs.total = res.data.count;
				});
			},
			//弹出图片
			handles(val) {
				console.log(val);
				this.src = val.face_url.src ?
					CONFIG.baseUrl +'static'+ val.face_url.src :
					"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg";
				this.$refs.diolg.dialogVisible = true;
			},
			dialogVisibletrue() {
				this.$refs.diolg.dialogVisible = false;
			},
			handleClose() {
				this.$refs.diolg.dialogVisible = false;
			},
			//选择框选中
			handleSelectionChange(val) {
				let id = [];
				val.forEach((element) => {
					id.push(element.id);
				});
				this.id = id.toString();
			},
			// 审核
			handRegister() {
				// this.$refs.selfauditdiolg.centerDialogVisible=true
				let data = {
					method: "co.person.more.approve",
					id: this.id,
					manager_id: this.UserInfo.manager_id,
					agent_id: this.UserInfo.agent_id,
				};
				this.$serve(data).then((res) => {
					if (res.data.code == 0) {
						this.$message({
							message: this.$t("devtable.shcg"),
							type: "success",
						});
					} else if (res.data.code == -18) {
						this.$message({
							message: this.$t("devtable.zcsbbcz"),
							type: "error",
						});
					} else if (res.data.code == -13) {
						this.$message({
							message: this.$t("devtable.sbbzx"),
							type: "error",
						});
					} else if (res.data.code == -14) {
						this.$message({
							message: this.$t("卡号已存在"),
							type: "error",
						});
					} else {
						this.$message({
							message: this.$t("devtable.zcrlbcz"),
							type: "error",
						});
					}
					this.getableDate();
				});
			},
			//批量审核
			handRegisterall() {},
			// index 自增获取每一页 的数据
			Geteverypage() {
				if (this.jj) {
					var timer = setInterval(() => {
						this.showid2 = [];
						let data = {
							method: "co.approve.list",
							page_index: this.indexst + 1,
							page_size: 10,
							name: "",
							mobile: "",
							manager_id: this.UserInfo.manager_id,
							card_no: "",
							agent_id: this.UserInfo.agent_id,
							reg_face_status: "",
							dept_id: "",
							approve_order_by: this.approve_order_by,
							status_order_by: this.status_order_by,
						};
						this.everypage = [];
						this.$serve(data).then((res) => {
							if (res.data.code == 0) {
								this.everypage = res.data.data;
								this.everypage.forEach((el) => {
									this.showid2.push(el.id);
									console.log(`6666666`, this.indexst);
									if (res.data.count > this.indexst * 10) {
										this.Geteverypage();
										this.Batchaudit();
									} else {
										clearTimeout(timer);
									}
								});
							}
						});
						this.jj = false;
					}, 6000);
				}
			},
			// 当index开始自增时，批量审核
			Batchaudit() {
				this.jj = true;
				let data = {
					method: "co.person.more.approve1",
					id: this.showid2.toString(),
					manager_id: this.UserInfo.manager_id,
					agent_id: this.UserInfo.agent_id,
					device_list_0: this.device_list_0,
					device_list_1: this.device_list_1,
					device_list_2: this.device_list_2,
					index: this.indexst,
				};
				this.$serve(data).then((res) => {
					console.log(`322`, res);
					if (res.data.code == 0) {
						this.device_list_0 = res.data.data.device_list_0;
						this.device_list_1 = res.data.data.device_list_1;
						this.device_list_2 = res.data.data.device_list_2;
						this.indexst = res.data.data.index;
					}
				});
			},
			//同步
			handRegistertbu() {
				var allDataTimer = null;
				var postFlag = true;
				var evershouid = [];
				var shouid = [];
				if (postFlag) {
					allDataTimer = setInterval(() => {
						let data = {
							method: "co.approve.list",
							page_index: this.indexst + 1,
							page_size: 10,
							name: "",
							mobile: "",
							manager_id: this.UserInfo.manager_id,
							card_no: "",
							agent_id: this.UserInfo.agent_id,
							reg_face_status: "",
							dept_id: "",
							approve_order_by: "-1",
							status_order_by: "-1",
						};
						this.$serve(data).then((res) => {
							console.log(`777`, res);
							let datalist = res.data.data;
							evershouid = [];
							shouid = [];
							datalist.forEach((el) => {
								evershouid.push(el.id);
								shouid = evershouid.concat();
							});
							console.log(`00000`, res.data.data)
							if (res.data.data.length != 0) {
								let pamsrt = {
									method: "co.person.more.approve",
									id: shouid.toString(),
									manager_id: this.UserInfo.manager_id,
									agent_id: this.UserInfo.agent_id,
									device_list_0: this.device_list_0,
									device_list_1: this.device_list_1,
									device_list_2: this.device_list_2,
									index: this.indexst,
									is_all_approve: 1,
								};

								this.$serve(pamsrt).then((res) => {
									console.log(`7778`, res);

									if (res.data.code == 0) {
										this.$message({
											message: this.$t("devtable.shcg"),
											type: "success",
										});
										this.device_list_0 = res.data.data.device_list_0;
										this.device_list_1 = res.data.data.device_list_1;
										this.device_list_2 = res.data.data.device_list_2;
										this.indexst = res.data.data.index;
									} else if (res.data.code == -18) {
										this.$message({
											message: this.$t("devtable.zcsbbcz"),
											type: "error",
										});
										postFlag = true;
									} else {
										this.$message({
											message: this.$t("devtable.zcrlbcz"),
											type: "error",
										});
									}
								});
							}
						});
						if ((this.indexst + 1) * 10 >= this.configs.total + 10) {
							clearInterval(allDataTimer);
							allDataTimer = null;
							//   this.getableDate();
						}
					}, 500);
				}
			},
			handFromshow() {
				this.iShow = !this.iShow;
			},
			onSubmit() {
				this.getableDate()
				// let data = {
				// method: "co.approve.list",
				// page_index: 1,
				// page_size: 10,
				// name: this.formInline.name,
				// mobile: this.formInline.mobile,
				// card_no: this.formInline.card_no,
				// agent_id: this.UserInfo.agent_id,
				// reg_face_status: "",
				// dept_id: this.formInline.dept_id,
				// };
				// this.$serve(data).then((res) => {
				// this.tableData = res.data.data;
				// this.tableData = res.data.data;
				// this.configs.total = res.data.count;
				// });
			},
		},
	};
</script>
<style lang="less" scoped>
	.datasoures {
		padding: 20px;
		background-color: #fff;

		.fromshea {
			margin-top: 20px;
			padding: 20px;
		}

		.shear {
			display: flex;
		}
	}

	.name {
		flex: 2;
		line-height: 30px;
	}

	.tbut {
		margin-left: 20px;
	}
</style>

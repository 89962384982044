<template>
    <div>
        <el-dialog
        center
        :title="$t('devtable.yl')"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
        :before-close="handleClose">

 
                <slot></slot>
 
        <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="dialogVisiblefalse">取 消</el-button> -->
            <el-button type="primary" @click="dialogVisibletrue">{{$t('tablename.qd')}}</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
var loginObj=JSON.parse(window.localStorage.getItem('user'));
export default {
    data (){
        return{
            dialogVisible:false
        }
    },
    props:{
  
    },
    methods:{
        
        dialogVisibletrue(){
            this.$emit('dialogVisibletrue')
        },
        handleClose(){
            this.$emit('handleClose')
        }
    }
}
</script>
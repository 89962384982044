<template>
    <div class="headertoab">
        <div class="dei_rowp">
            <el-row :gutter="2">
                <el-col :span="9">
                    <el-button type="primary" class="but"  size="small" @click="handRegister">
                        <i class="el-icon-check"></i>
                        {{titlename.name}}
                    </el-button>
                </el-col>
                <!-- <el-col :span="5">
                    <el-button type="primary" class="but"  size="small" @click="handRegisterall">
                        <i class="el-icon-check"></i>
                        {{titlename.titles}}
                    </el-button>
                </el-col> -->
                <!-- <el-col :span="7"> -->
                    <!-- <el-button type="primary" class="but"  size="small" @click="handRegistertbu"> -->
                        <!-- <i class="el-icon-check"></i> -->
                        <!-- {{titlename.tlnames}} -->
                    <!-- </el-button> -->
                <!-- </el-col> -->
                <el-col :span="9">
                    <div @click="handFromshow"><el-input
                    class="dev_dowm"
                    :placeholder="$t('devtable.qdjcx')"
                    suffix-icon="el-icon-arrow-down"
                    :readonly="true"
                     size="small"
                    >
                    </el-input></div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        titlename:Object,
        flags:Boolean
    },
    data(){
        return{
           

            }
        },
    methods:{
        handRegister(){
            this.$emit('handRegister')
        },
        handFromshow(){
            this.$emit('handFromshow')
        },
        Excelexport(){
            this.$emit('Excelexport')
        },
        handRegisterall(){
            this.$emit('handRegisterall')
        },
        handRegistertbu(){
            this.$emit('handRegistertbu')
        }
    }
}
</script>
<style lang="less" scoped>
// .headertoab{
//     display: flex;
//     justify-content: space-between;
// }
.dei_rowp{
    padding-left: 20px;
    // width: 50%;
}
</style>
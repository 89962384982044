<template>
  <div class="tabletemplate">
      <slot name="hdfroms"></slot>
      <el-table
        :data="tableData"
        :tableLabel="tableLabel"
        v-loading="configs.loading"
        border
        @selection-change="handleSelectionChange"
        @select-all="selectAll"
        @sort-change="sortChange"
        style="width: 100%">
        <!-- 多选框 -->
        <el-table-column
          v-if="flags.show"
          type="selection"
          align="center"
          label="全选"
          width="40">
        </el-table-column>
        <!-- 序号 -->
        <el-table-column
         v-if="flags.ishow"
          type="index"
          width="115"
          align="center"
          :label="$t('devtable.xh')"
          >
        </el-table-column>

      <el-table-column
        v-for="item in tableLabel"
        :key="item.prop"
        :label="item.label"
        :width="item.width? item.width :'auto' "
        :align="item.align"
        :show-overflow-tooltip='true'
        :sortable="item.sortable"
        >
        <template slot-scope="scope">
          <!-- <i class="el-icon-time"></i> -->
          <!-- 是对象的 -->
          <!-- {{ typeof scope.row[item.prop]}} -->
		  <!-- {{JSON.stringify(scope.row[item.prop])}} -->
          <span v-if="typeof scope.row[item.prop] == 'object' " style="margin-left: 10px">
            <!-- {{ scope.row[item.prop].name}} -->
            <el-button v-if="scope.row[item.prop].but" :type="scope.row[item.prop].type" @click="handles(scope.row)" size="small">{{ scope.row[item.prop].name}}</el-button>
            <el-button v-if="scope.row[item.prop].state" :type="scope.row[item.prop].type" size="small">{{ scope.row[item.prop].name}}</el-button>
			<el-button v-if="scope.row[item.prop].is_syn" :type="scope.row[item.prop].type" size="small" @click="handleSyn(scope.row)">{{ scope.row[item.prop].name}}</el-button>
            <div  v-if="scope.row[item.prop].photo" style="width:80px;height:40px">
              <el-image width:="80px" height="40px" :src="scope.row[item.prop].name" @click="handimage(scope.row)"></el-image>
            </div>
          </span>
          <!-- 不是对象的 -->
          <span v-else  style="margin-left: 10px">
            {{ scope.row[item.prop] }}
          </span>
        </template>
      </el-table-column>

      <!-- 操作 -->
      <el-table-column  v-if="operation" :label="$t('tablename.cz')" align="center" :width="operation.width? operation.width:''">
        <template slot-scope="scope">

          <el-button
            :type="itemc.type"
            size="small"
            v-for="itemc in operation.data"
            :key="itemc.name"
            @click="handlerow(scope.$index, scope.row,itemc.name)">{{itemc.name}}</el-button>

        </template>
      </el-table-column>
      </el-table>
      <div class="pagess">
        <el-pagination
      @size-change="handleSizeChange"
      @current-change="handlpage"
      :current-page="1"
      :page-sizes="[10, 20, 30, 40]"
      :pageSize="configs.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="configs.total">
    </el-pagination>
          <!-- <el-pagination -->
            <!-- background -->
            <!-- layout="prev, pager, next" -->
            <!-- @current-change="handlpage" -->
            <!-- :page-count="configs.pageCount" -->
            <!-- :pageSize="configs.pagesize" -->
            <!-- :total="configs.total"> -->
          <!-- </el-pagination> -->
      </div>
      <slot name="Dialogs"></slot>
  </div>
</template>
<script>
export default {
  props: {
    tableData: Array, // 表格数据
    tableLabel: Array, // 表头数据
    flags: Object,
    configs: Object,
    operation: Object, // 操作neir,
    sortable: Boolean,
  },
  data() {
    return {
      // srcList:[],
      multipleSelection: [],
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  methods: {
	handleSyn(v){
		this.$emit("handleSyn", v);
	},
    // 单元格点击方法
    handles(v) {
      this.$emit("handles", v);
    },
    // 编辑方法
    // handleEdit (h, v) {
    //   this.$emit('handleEdit', v)
    // },
    // 按钮点击事件
    handlerow(h, v, name) {
      this.$emit("handlerow", v, name);
    },
    
    // 多选框方法
    handleSelectionChange(val) {
      // console.log(val)
      this.$emit("handleSelectionChange", val);
      this.multipleSelection = val;
    },
    // 排序
    sortChange(v) {
      this.$emit("sortChange", v);
    },
    selectAll(selection, first) {
      console.log(selection);
    },
    // 分页
    handlpage(v) {
      console.log(`000`, v);
      this.$emit("handlpages", v);
    },
    handleSizeChange(v){
      this.$emit("handleSizeChange", v);
    },
    handimage(v) {
      this.$emit("handimage", v);
    },
  },
};
</script>
<style lang="less" scoped>
.tabletemplate {
  padding: 20px;
  .pagess {
    padding-top: 20px;
  }
}
</style>

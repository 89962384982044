<template>
    <el-select v-model="mineStatus" :placeholder="$t('devtable.qxz') " size="small" multiple>
        <el-option :value="mineStatus"  style="height: auto">

            <!-- <el-tree :data="treedata"
            default-expand-all show-checkbox
            check-strictly node-key="id"
                ref="tree" highlight-current :props="defaultProps"
            @check-change="handleCheckChange"></el-tree> -->

            <el-tree
                default-expand-all
                :data="treedata"
                check-strictly
                show-checkbox
                node-key="id"
                :default-expanded-keys="[2, 3]"
                :default-checked-keys="[5]"
                ref="tree" highlight-current :props="defaultProps"
                @check-change="handleCheckChange"
            >
            </el-tree>

        </el-option>
    </el-select>
</template>
<script>
import methodlist from '@/utils/methods'
import {Qiyebum,todeepName} from '@/utils/index'
export default {
    data(){
        return{
            mineStatus: [],
            treedata: [],
            defaultProps: {
                children: "children",
                label: "name",
                value:'id',
                disabled: "is_valid"
            },
            UserInfo:'',
        }
    },
    props:{
        valuet:Number
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getDeptDataList()
    },
    watch:{
        'valuet':function(onal,nval){
            if(onal!=nval){
                this.gethandeepName()
                console.log(11)
            }
        }
    },
    methods:{
        gethandeepName(){
            // console.log(`99`,this.valuet)
            if(this.valuet){
               this.mineStatus= todeepName(this.valuet,this.treedata)
            }
        },
        //获取企业架构
        getDeptDataList(){
            const params = {
                method: methodlist.ee_dept_list,
                agent_id:this.UserInfo.agent_id,
                manager_id:this.UserInfo.manager_id,
            }
            this.$serve(params).then(res => {    
                // console.log(`909`,res)   
                var result = [];
                if(res.data.data != null)
                result.push(this.getFirstObj(res.data.data))
                // console.log(`6788`,result)
                this.treedata=Qiyebum(result)
                
            }).then(res=>{
                this.gethandeepName()
            });
        },

        
        getFirstObj(obj){
            for(var key in obj){
                return obj[key];
            }      
        },
        //获取企业部门id
        handleCheckChange(data, checked, node) {
            
             let res = this.$refs.tree.getCheckedNodes(false, true); //true，1. 是否只是叶子节点 2.选择的时候不包含父节点）
            if (checked) {
                this.$refs.tree.setCheckedNodes([data]);
            }
            let arrLabel = [];
            let dept_idlist = []
            // console.log(res, 'res')
            res.forEach(item => {

                arrLabel.push(item.name)
                dept_idlist.push(item.id)

                // if (arrLabel.length === 0) {
                //     arrLabel.push(item.name);
                // } else {
                //     arrLabel.length === 0;
                // }
            });
            this.mineStatus = arrLabel;

            this.dept_id = data.id
            this.$emit('handleCheckChange',this.dept_id)
        },
    }
}
</script>
<style scoped>
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
     background-color: #fff;
}
</style>
import { expr } from "jquery"

import { Message, Loading } from 'element-ui'

// 把文件按照二进制进行读取
export function readFile(file) {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.readAsBinaryString(file) // 将当前文件以二进制的形式读取
    reader.onload = ev => {
      resolve(ev.target.result)
    }
  })
}
// 设置异步间隔延迟
export function delay(interval = 0) {
  return new Promise(resolve => {
    let timer = setTimeout(_ => {
      clearTimeout(timer)
      resolve()
    }, interval)
  })
}
// 字段对应表
export let character = {
  name: {
    text: '姓名',
    type: 'string'
  },
  dept_name: {
    text: '企业部门',
    type: 'string'
  },
  gender: {
    text: '性别',
    type: 'number'
  },
  mobile: {
    text: '手机号码',
    type: 'string'
  },
  // credential: {
  //   text: '证件号码',
  //   type: 'string'
  // },
  card_no: {
    text: '卡号',
    type: 'string'
  },
  number: {
    text: '工号',
    type: 'string'
  },
  // start_time: {
  //   text: '开始时间',
  //   type: 'string'
  // },
  // end_time: {
  //   text: '结束时间',
  //   type: 'string'
  // }
}
// 时间戳转日期
export function getLocalTime(nS) {
  return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
}




// export function timer(list, object) {
// 
  // intervalData(list, object);
// 
// }
// 
// export function intervalData(list, object) {
//  
  // intertimer = setInterval(() => {
    // 每三秒调用一次方法
    // if (list.length==0) {
      // clearInterval(intertimer); //关闭定时器
      // loading.close(); //关闭弹窗    
    // }
    // hanldArray(list.splice(0,10),object)
// 
  // }, 3000);
  // 
// }

var timestamp = Date.parse(new Date())/1000; 

// 2021/5/12 上午9:47



var timesend =Date.parse(new Date(timestampToTime(timestamp)))/1000; 

// 导入处理数据
export function hanldArray(list, object) {
  console.log(`666`, list)
  console.log(`777`, object)
  dataid2 = []
  let name = []
  let mobile = []
  let gender = []
  let face_url = []
  let dept_id = []
  let credential = []
  let card_no = []

  let origin = []
  let birthplace = []
  let type = []
  let credential_type = []
  let birthday = []
  let nation = []
  let number = []
  let door_password = []
  let remark = []
  let start_time = []
  let end_time = []
  list.forEach((el, index) => {
    dataid = []
    datas = []
    name.push(el.name)
    mobile.push(el.mobile)

    gender.push(el.gender)
    face_url.push(el.face_url)
    dept_id = dataid2
    credential.push(el.credential)
    card_no.push(el.card_no)
    // gender.push(element.gender)
    origin.push(el.origin ? el.origin : "")
    start_time.push(el.start_time ? el.start_time : timestamp)
    end_time.push(el.end_time ? el.end_time : timesend)
    birthplace.push(el.birthplace)
    type.push(el.type)
    credential_type.push(el.credential_type)
    birthday.push(el.birthday)
    nation.push(el.nation)
    number.push(el.number)
    door_password.push(el.door_password ? el.door_password : "")
    remark.push(el.remark ? el.remark : "")
    handDdept_id(el.dept_name, object)
    if (dataid.length == 0) {
      Message({
        type: 'warning',
        message: `${el.dept_name} 第${index + 1} 条不存在部门分类中`
      })
      console.log(`${el.dept_name} 第${index + 1} 条不存在部门分类中`)
      return false
    } else {
		
		console.log('dataid2',dataid)
      dataid2.push(dataid.toString())
      datas2.push(datas.toString())
    }
  })
  console.log('dept_id',dept_id)
  let data = {}
  data.name = name.toString()
  data.mobile = mobile.toString()
  data.gender = gender.toString()
  data.face_url = face_url.toString()
  data.dept_id = dept_id.toString()
  data.credential = credential.toString()
  data.card_no = card_no.toString()

  data.start_time = start_time.toString()
  data.end_time = end_time.toString()

  data.birthplace = birthplace.toString()
  data.type = type.toString()
  data.credential_type = credential_type.toString()
  data.birthday = birthday.toString()
  data.nation = nation.toString()
  data.number = number.toString()
  data.door_password = door_password.toString()
  data.remark = remark.toString()
  data.origin = origin.toString()
  console.log(`66666`, data)
  // var Personneldata=[]
  // Personneldata.push(data)
  return data
}
//  递归处理dep_id
var datas = []
var dataid = []
var datas2 = []
var dataid2 = []
export function handDdept_id(name, object) {
  for (var i in object) {
    if (name == object[i].name) {
		console.log('1234',object[i].id,object[i].name)
      dataid.push(object[i].id)
      datas.push(object[i].name)
    }
    if (object[i].children) {
      handDdept_id(name, object[i].children);
    }
  }
}

/**
 * 
 * @param {*} object 
 * 处理部门有些不能选中的
 */
export function Qiyebum(object) {
  for (var i in object) {
    if (object[i].is_valid == 0) {
      object[i].is_valid = false
    } else {
      object[i].is_valid = true
    }
    if (object[i].children) {
      Qiyebum(object[i].children);
    }
  }
  return object
}

/**
 * 
 * @param {*} str 
 * 根据部门id 得到部门名称
 */
var deepName = []
export function getdeepName(id, object) {
  // deepName=[]  
  for (var i in object) {
    if (id == object[i].id) {
      deepName.push(object[i].name)
    }
    if (object[i].children) {
      getdeepName(id, object[i].children);
    }
  }
}
export function todeepName(id, object) {
  deepName = []
  getdeepName(id, object)
  return deepName
}


// 分钟转换成小时

export function ChangeHourMinutestr(str) {
  return (str / 60).toFixed(2)
}

/**
 * 去除最开始的0
 * @param {*} str 
 */

export function clearzero(str) {
  return (str.replace(/\b(0+)/gi, ""))
}

// 
export function twofour(num) {
  if (num == '00') {
    num = 24
  }
  return num
}


// 分批处理

export function timestampToTime(timestamp) {
  var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
 var Y = date.getFullYear() + 5 + '-';
  
 var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
 var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
 var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
 var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
 var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
  return Y+M+D
}

export function timestampToTime2(timestamp) {
  var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
 var Y = date.getFullYear() + 5 + '-';
  
 var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
 var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
 var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
 var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
 var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
  return Y+M+D
}


<template>
    <div>
        <el-dialog
            title="添加人员资料"
            :visible.sync="centerDialogVisible"
            width="55%"
            append-to-body
            center>

            <el-row :gutter="50" class="sefrtop">
                <el-col :span="16">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm">
                        <el-row >
                            <el-col :span="12">
                                <el-form-item size="small" label="姓名" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item size="small" label="手机号码" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row >
                            <el-col :span="12">
                                <el-form-item label="性别" size="small" prop="region">
                                    <el-select v-model="ruleForm.region">
                                    <el-option label="区域一" value="shanghai"></el-option>
                                    <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="证件类型" size="small" prop="region">
                                    <el-select v-model="ruleForm.region">
                                    <el-option label="区域一" value="shanghai"></el-option>
                                    <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row >
                            <el-col :span="12">
                                <el-form-item label="籍贯" size="small" prop="region">
                                    <el-select v-model="ruleForm.region">
                                    <el-option label="区域一" value="shanghai"></el-option>
                                    <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item size="small" label="证件号" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row >
                            <el-col :span="12">
                                <el-form-item size="small" label="户籍" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item size="small" label="出生日期" prop="name">
                                    <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date1" style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row >
                            <el-col :span="12">
                                <el-form-item size="small" label="人员类型" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="民族" size="small" prop="region">
                                    <el-select v-model="ruleForm.region">
                                    <el-option label="区域一" value="shanghai"></el-option>
                                    <el-option label="区域二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        
                        <el-form-item size="small" label="企业部门" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>

                        <el-row >
                            <el-col :span="12">
                                <el-form-item size="small" label="工号" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item size="small" label="卡号" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="10">
                            <el-col :span="11">
                                <el-form-item size="small" label="开门密码" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2">
                                <el-checkbox-group v-model="ruleForm.type" style="line-height: 32px;">
                                    <el-checkbox label="启用" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item size="small" label="备注" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
                <el-col :span="8">
                    <el-image :src="src" style="width:203px;height:250px"></el-image>
                    <el-col style="margin-top:10px">
                        <el-row>
                            <el-button type="primary" size="small">拍照</el-button>
                            <el-button type="primary" size="small">从文件选择</el-button>
                        </el-row>
                    </el-col>
                    <div class="phonefp">
                        <p>照片采集要求：</p>
                        <p>1.正面人脸免冠半身照</p>
                        <p>2.人脸画面占比2/3以上</p>
                    </div>
                </el-col>
                
            </el-row>
            <div class="seft_row">审核权限管理</div>
            <div class="shfqx_row">
                <el-row style="margin-bottom:20px" :gutter="10">
                    <el-col :span="5"> 
                        <el-input v-model="ruleForm.name" size="small"></el-input>
                    </el-col>
                    <el-col :span="2"> 
                        <el-button type="primary" size="small">查询</el-button>
                    </el-col>
                    <el-col :span="3"> 
                        <span>有效期开始：</span>
                    </el-col>
                    <el-col :span="5">
                        <el-date-picker size="small" type="date" placeholder="选择日期" v-model="value1" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col :span="3"> 
                        <span>有效期结束：</span>
                    </el-col>
                    <el-col :span="5">
                        <el-date-picker
                        style="width: 100%;"
                        size="small"
                        v-model="value1"
                        type="date"
                        placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                </el-row>

                <el-table
                :data="tableData"
                stripe
                style="width: 100%">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                prop="date"
                label="设备名称"
                width="180">
                </el-table-column>
                <el-table-column
                prop="name"
                label="安装地址"
                width="180">
                </el-table-column>
                <el-table-column
                prop="address"
                label="设备序列号">
                </el-table-column>
            </el-table>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="centerDialogVisible = false">保  存</el-button>
                
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return{
            value1:'',
            src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            centerDialogVisible:false,
            ruleForm: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                    ],
                    region: [
                        { required: true, message: '请选择活动区域', trigger: 'change' }
                    ],
                    date1: [
                        { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
                    ],
            },
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
                }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
                }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
                }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }]
        }
    },
    mounted(){

    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
.perconte_row{
    
}
.phonefp{
    margin-top:65px;
    p{
        margin-top: 10px;
        color: red;
        
    }
}
.seft_row{
    text-align: center;
    border-bottom: 1px solid #ccc;
    height: 30px;
}
.shfqx_row{
    margin-top:20px;
    span{
        line-height: 32px;
        text-align: center;
        margin-left: 5px
    };
}
</style>